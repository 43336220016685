import React from 'react';

import Footer from './footer';
import Navbar from './Navbar';

import './core.css';

const Core = ({ children }) => (
  <React.Fragment>
    <main>{children}</main>
    <Footer />
    <Navbar />
  </React.Fragment>
);

export default Core;
