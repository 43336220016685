import * as React from 'react';
import { Link } from 'gatsby';

import colors from '../../../colors/colors';

const InternalLinks = () => (
  <>
    <li>
      <Link style={{ color: colors.yellowDesaturated }} to='/#Series'>
        Series
      </Link>
    </li>
    <li>
      <Link style={{ color: colors.yellowDesaturated }} to='/#FAQs'>
        FAQs
      </Link>
    </li>
    <li>
      <Link style={{ color: colors.yellowDesaturated }} to='/#Roadmap'>
        Roadmap
      </Link>
    </li>
    <li>
      <Link style={{ color: colors.yellowDesaturated }} to='/#Bespoke'>
        Bespoke
      </Link>
    </li>
    <li>
      <Link style={{ color: colors.yellowDesaturated }} to='/partners'>
        Partners
      </Link>
    </li>
    <li>
      <Link style={{ color: colors.yellowDesaturated }} to='/terms'>
        Terms
      </Link>
    </li>
    <li>
      <Link style={{ color: colors.yellowDesaturated }} to='/'>
        Home
      </Link>
    </li>
  </>
);

export default InternalLinks;
