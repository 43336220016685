import * as React from 'react';

import flexUtilities from './flexUtilities';

import './columns.css';

const ThreeColumns = ({ justify, align, style = {}, wrap = false, children }) => (
  <div
    className='ThreeColumnRow'
    style={{
      justifyContent: flexUtilities.justifyContent(justify),
      alignItems: flexUtilities.alignItems(align),
      flexWrap: wrap ? 'wrap' : 'nowrap',
      ...style,
    }}
  >
    {children}
  </div>
);

export default ThreeColumns;
