import * as React from 'react';

import colors from '../../colors/colors';

import FlexWrapper from '../display/FlexWrapper';

import Menu from '../icons/Menu';
import MenuClose from '../icons/MenuClose';

import ExternalLinks from './NavbarLinks/ExternalLinks';
import InternalLinks from './NavbarLinks/InternalLinks';

import './navbar.css';
import BoogieWoogieLogo from '../icons/BoogieWoogieLogo';

const Navbar = () => {
  const [mobileExpanded, toggleMobileExpanded] = React.useState(false);

  return (
    <nav
      style={{
        backgroundColor: colors.blue,
      }}
    >
      {/* <div style={{ width: 'auto', height: '2rem' }}>
        <BoogieWoogieLogo aspect='100%' color={colors.yellowSaturated} />
      </div> */}
      <div className='NavBarMain'>
        <ul>
          <ExternalLinks />
        </ul>
        <ul>
          <InternalLinks />
        </ul>
      </div>
      <div className='NavBarMobile'>
        <FlexWrapper align='center' justify='between' className='NavBarMobileBar'>
          <div></div>
          <div
            onClick={() => {
              toggleMobileExpanded(!mobileExpanded);
            }}
          >
            {mobileExpanded ? (
              <MenuClose aspect='1.5rem' color={colors.yellowSaturated} />
            ) : (
              <Menu aspect='1.5rem' color={colors.yellowDesaturated} />
            )}
          </div>
        </FlexWrapper>
        <div
          className='NavBarMobileTray'
          style={{
            right: mobileExpanded ? 0 : '-100%',
            backgroundColor: colors.blue,
          }}
        >
          <ul>
            <InternalLinks />
            <ExternalLinks />
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
