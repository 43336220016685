export default {
  blue: 'var(--brand-color-blue)',
  green: 'var(--brand-color-green)',
  grey: 'var(--brand-color-grey)',
  medGrey: 'var(--brand-color-medGrey)',
  offWhite: 'var(--brand-color-offWhite)',
  red: 'var(--brand-color-red)',
  white: 'var(--brand-color-white)',
  yellowDesaturated: 'var(--brand-color-yellowDesaturated)',
  yellowSaturated: 'var(--brand-color-yellowSaturated)',
  darkGrey: 'var(--brand-color-darkGrey)',
};
