import * as React from 'react';

import colors from '../../colors/colors';

import FlexWrapper from '../display/FlexWrapper';
import ThreeColumns from '../display/ThreeColumns';

import BoogieWoogieLogo from '../imageWrappers/BoogieWoogieLogo';

import Discord from '../icons/Discord';
import Medium from '../icons/Medium';
import Twitter from '../icons/Twitter';

import links from '../../data/links';

const footerStyles = {
  backgroundColor: colors.red,
};

const columnStyles = {
  padding: '2rem 1rem',
  order: 1,
};

const fontColor = {
  color: colors.white,
};

const headerStyles = {
  ...fontColor,
  margin: '0 0 1rem',
  fontSize: '1.1rem',
};

const linkStyles = {
  ...fontColor,
  display: 'block',
  textDecorationColor: colors.yellowDesaturated,
  fontSize: '0.85rem',
  margin: '0.75rem 0',
  height: '1.25rem',
};

const Footer = () => (
  <footer style={footerStyles}>
    <ThreeColumns align='stretch'>
      <FlexWrapper align='center' justify='center' style={columnStyles}>
        <div style={{ width: '60%' }}>
          <BoogieWoogieLogo />
        </div>
      </FlexWrapper>
      <div style={columnStyles}>
        <h3 style={headerStyles}>Dandy Lines Boogie-Woogie</h3>
        <a style={linkStyles} href='https://boogiewoogienft.medium.com'>
          <FlexWrapper align='center' justify='start'>
            <Medium aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Medium</span>
          </FlexWrapper>
        </a>
        <a style={linkStyles} href='https://twitter.com/BoogieWoogieNFT'>
          <FlexWrapper align='center' justify='start'>
            <Twitter aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Twitter - @BoogieWoogieNFT</span>
          </FlexWrapper>
        </a>
        <a style={linkStyles} href={links.discord}>
          <FlexWrapper align='center' justify='start'>
            <Discord aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Discord</span>
          </FlexWrapper>
        </a>
      </div>
      <div style={columnStyles}>
        <h3 style={headerStyles}>Dandy Lines</h3>
        <a style={linkStyles} href='https://dandylines.io/'>
          <FlexWrapper align='center' justify='start'>
            <Medium aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Site</span>
          </FlexWrapper>
        </a>
        <a style={linkStyles} href='https://twitter.com/dandylinesNFT'>
          <FlexWrapper align='center' justify='start'>
            <Twitter aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Twitter - @DandyLinesNFT</span>
          </FlexWrapper>
        </a>
        <a style={linkStyles} href='https://dandylines.medium.com/'>
          <FlexWrapper align='center' justify='start'>
            <Medium aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Medium</span>
          </FlexWrapper>
        </a>
      </div>
    </ThreeColumns>
  </footer>
);

export default Footer;
