import * as React from 'react';

import Discord from '../../icons/Discord';
import Medium from '../../icons/Medium';
import Twitter from '../../icons/Twitter';

// import OpenSea from '../../icons/OpenSea';

import links from '../../../data/links';

const ExternalLinks = () => (
  <>
    {/*
    <li>
      <OpenSea aspect='1.5rem' fog />
    </li>
    */}
    <li>
      <a href='https://boogiewoogienft.medium.com'>
        <Medium aspect='1.5rem' />
      </a>
    </li>
    <li>
      <a href='https://twitter.com/BoogieWoogieNFT'>
        <Twitter aspect='1.5rem' />
      </a>
    </li>
    <li>
      <a href={links.discord}>
        <Discord aspect='1.5rem' />
      </a>
    </li>
  </>
);

export default ExternalLinks;
