import * as React from 'react';

import colors from '../../colors/colors';
import SVGWrapper from '../display/SVGWrapper';

const MenuClose = ({ aspect = '2rem', color = colors.yellowDesaturated }) => (
  <SVGWrapper viewBox='0 0 24 24' aspect={aspect} stroke={color}>
    <line x1='3' y1='12' x2='21' y2='12'></line>
    <line x1='3' y1='6' x2='21' y2='6'></line>
    <line x1='3' y1='18' x2='21' y2='18'></line>
  </SVGWrapper>
);

export default MenuClose;
